<script setup lang="ts">
import Spinner from '../ui/spinner/Spinner.vue';

// PROPS
interface Props {
    size?: number;
}
const props = defineProps<Props>();
</script>
<template>
    <div class="flex justify-center items-center">
        <Spinner :size="props.size"></Spinner>
    </div>
</template>
